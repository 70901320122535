<template>
  <v-container>
    <v-progress-linear
      v-show="progress"
      color="primary"
      indeterminate
      rounded
      height="6"
    />
    <div
      style="
        padding: 2px;
        height: 660px;
        width: 100%;
        overflow: auto;
        scrollbar-width: thin !important;
      "
    >
      <v-card>
        <v-card-text>
          <v-list three-line>
            <v-list-item v-for="(data, i) in listLabor" :key="i + 1">
              <v-list-item-content>
                <v-list-item-title
                  ><b>No. Folio</b> : {{ data.folio_no }}</v-list-item-title
                >
                <v-list-item-title
                  ><b>No. Reg : </b>
                  {{ data.registration_no }}
                  <br />
                  <b>Tanggal Hasil :</b>
                  {{ data.service_date | moment("DD MMMM YYYY") }}
                </v-list-item-title>

                <v-divider class="mt-1"></v-divider>
                <v-list-item-subtitle>
                  <div class="mt-1 mb-2">
                    <div v-if="data.unit_id == 20">
                      <v-btn
                        @click="
                          liatHasilLabor(data.registration_id, data.folio_id)
                        "
                        medium
                        dark
                        color="success"
                        class="mt-1 mb-1 btn-block"
                      >
                        <v-icon fab>mdi-magnify</v-icon> &nbsp;Laboratorium
                      </v-btn>
                      <v-btn
                        @click="
                          LiatHasilLaborLuar(
                            data.registration_no,
                            data.patient_id
                          )
                        "
                        medium
                        color="orange"
                        dark
                        class="mt-1 mb-1 btn-block"
                        >Hasil Laboratorium PK LUAR</v-btn
                      >
                      <!-- <div>{{ listLaborLuar }}</div> -->
                    </div>
                    <div v-if="data.unit_id == 157">
                      <v-btn
                        @click="
                          LiatHasilLaborLuar(
                            data.registration_no,
                            data.patient_id
                          )
                        "
                        medium
                        color="teal"
                        dark
                        class="mt-1 mb-1 btn-block"
                        >Hasil Laboratorium PA</v-btn
                      >
                      <!-- <div>{{ listLaborLuar }}</div> -->
                    </div>

                    <div v-if="data.unit_id == 21">
                      <v-btn
                        @click="
                          liatHasilPenunjang(
                            data.registration_id,
                            data.folio_id
                          )
                        "
                        dark
                        medium
                        color="red"
                        class="mt-1 mb-1 btn-block"
                      >
                        <v-icon fab>mdi-magnify</v-icon> &nbsp;Radiologi
                      </v-btn>
                    </div>
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
      <v-row justify="center">
        <v-dialog v-model="showDialogPenunjang" max-width="1280px">
          <v-card>
            <v-card-title class="headline"> Hasil Penunjang </v-card-title>
            <v-card-text>
              <div id="hasil"></div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
  </v-container>
</template>
<script>
import { apiKedua, baseUrlV2 } from '../plugins/supports'

export default {
  name: 'ListLaborHome',
  props: {
    registration_id: {
      type: String,
      default: '',
      required: false
    },
    no_rm: {
      type: String,
      default: '',
      required: false
    }
  },
  data () {
    return {
      folioYangDipakai: '',
      progress: false,
      listLabor: [],
      listLaborLuar: [],
      showDialogPenunjang: false,
      url_emr: 'http://emr.rs-syafira.com/'
    }
  },
  created () {
    // this.getHistoryLabor()
  },
  methods: {
    getHistoryLabor () {
      this.progress = true

      setTimeout(() => {
        apiKedua
          .get('labor/list-history-labor?no_rm=' + this.no_rm)
          .then((res) => {
            if (res.data.con) {
              this.listLabor = res.data.results
            } else {
              this.listLabor = []
            }
          })
        this.progress = false
      }, 1000)
    },
    liatHasilLabor (v, folioid) {
      console.log(v)
      this.showDialogPenunjang = true
      // eslint-disable-next-line no-undef
      $.ajax({
        url: baseUrlV2 + 'rme/labor-view?folio_id=' + folioid,
        // eslint-disable-next-line no-undef
        type: 'GET',
        success: (result) => {
          // // console.log(result);
          // eslint-disable-next-line no-undef
          $('#hasil').html(result)
          // // eslint-disable-next-line no-undef
          // $('#mymodal2').modal({ show: true })
        }
      })
    },
    liatHasilPenunjang (v, folioid) {
      console.log(v)
      this.showDialogPenunjang = true
      // eslint-disable-next-line no-undef
      $.ajax({
        url: baseUrlV2 + 'rme/radiologi-view?folio_id=' + folioid,
        // eslint-disable-next-line no-undef
        type: 'GET',
        success: (result) => {
          // // console.log(result);
          // eslint-disable-next-line no-undef
          $('#hasil').html(result)
          // // eslint-disable-next-line no-undef
          // $('#mymodal2').modal({ show: true })
        }
      })
    },
    LiatHasilLaborLuar (v, e) {
      this.showDialogPenunjang = true

      // console.log(e)
      // var self = this
      // var listLaborLuar = [
      // eslint-disable-next-line no-undef
      $.get(
        baseUrlV2 +
          'rme/hasil-labor-luar-view?no_reg=' +
          v +
          '&patient_id=' +
          e,
        // 'http://hr.rs-syafira.com/elFinder_hr/file_get_contents_cek_folder.php?dir=//100.100.100.6/erm/LAB/303067/R-23017341/',
        (res) => {
          // console.log(res + 'HAS')
          // var dataSplit = res
          // for (const a of res) {
          //   // console.log(a)
          //   listLaborLuar.push({
          //     item : a
          //   })
          // }
          // self.listLaborLuar = res

          // self.listLaborLuar = res
          // console.log(dataSplit + 'Hasil Laboar')
          // eslint-disable-next-line no-undef
          $('#hasil').html(res)
        }
      )
      // console.log(this.listLaborLuar)
    }
  }
}
</script>
