var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.progress),expression:"progress"}],attrs:{"color":"primary","indeterminate":"","rounded":"","height":"6"}}),_c('div',{staticStyle:{"padding":"2px","height":"660px","width":"100%","overflow":"auto","scrollbar-width":"thin !important"}},[_c('v-card',[_c('v-card-text',[_c('v-list',{attrs:{"three-line":""}},_vm._l((_vm.listLabor),function(data,i){return _c('v-list-item',{key:i + 1},[_c('v-list-item-content',[_c('v-list-item-title',[_c('b',[_vm._v("No. Folio")]),_vm._v(" : "+_vm._s(data.folio_no))]),_c('v-list-item-title',[_c('b',[_vm._v("No. Reg : ")]),_vm._v(" "+_vm._s(data.registration_no)+" "),_c('br'),_c('b',[_vm._v("Tanggal Hasil :")]),_vm._v(" "+_vm._s(_vm._f("moment")(data.service_date,"DD MMMM YYYY"))+" ")]),_c('v-divider',{staticClass:"mt-1"}),_c('v-list-item-subtitle',[_c('div',{staticClass:"mt-1 mb-2"},[(data.unit_id == 20)?_c('div',[_c('v-btn',{staticClass:"mt-1 mb-1 btn-block",attrs:{"medium":"","dark":"","color":"success"},on:{"click":function($event){return _vm.liatHasilLabor(data.registration_id, data.folio_id)}}},[_c('v-icon',{attrs:{"fab":""}},[_vm._v("mdi-magnify")]),_vm._v("  Laboratorium ")],1),_c('v-btn',{staticClass:"mt-1 mb-1 btn-block",attrs:{"medium":"","color":"orange","dark":""},on:{"click":function($event){return _vm.LiatHasilLaborLuar(
                          data.registration_no,
                          data.patient_id
                        )}}},[_vm._v("Hasil Laboratorium PK LUAR")])],1):_vm._e(),(data.unit_id == 157)?_c('div',[_c('v-btn',{staticClass:"mt-1 mb-1 btn-block",attrs:{"medium":"","color":"teal","dark":""},on:{"click":function($event){return _vm.LiatHasilLaborLuar(
                          data.registration_no,
                          data.patient_id
                        )}}},[_vm._v("Hasil Laboratorium PA")])],1):_vm._e(),(data.unit_id == 21)?_c('div',[_c('v-btn',{staticClass:"mt-1 mb-1 btn-block",attrs:{"dark":"","medium":"","color":"red"},on:{"click":function($event){return _vm.liatHasilPenunjang(
                          data.registration_id,
                          data.folio_id
                        )}}},[_c('v-icon',{attrs:{"fab":""}},[_vm._v("mdi-magnify")]),_vm._v("  Radiologi ")],1)],1):_vm._e()])])],1)],1)}),1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"1280px"},model:{value:(_vm.showDialogPenunjang),callback:function ($$v) {_vm.showDialogPenunjang=$$v},expression:"showDialogPenunjang"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Hasil Penunjang ")]),_c('v-card-text',[_c('div',{attrs:{"id":"hasil"}})])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }