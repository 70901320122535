<template>
  <v-container fluid>
    <p class="text-h4 text--primary mt-5">
      Hi, {{ user ? user.employee_name + "," + user.salutation : "-" }}
    </p>
    <!-- <p>{{ showmessg }}</p> -->
    <!-- <button class="">Satu</button> -->

    <v-tabs
      slider-color="red"
      v-model="tab"
      centered
      stacked
      show-arrows
      scrollable
      danse
    >
      <v-tab> Home </v-tab>
      <!-- <v-tab> Analisa Kunjangan Pasien </v-tab> -->
    </v-tabs>
    <div :hidden="tab !== 0">
      <div v-if="tab === 0">
        <v-row class="mt-2 m-1">
      <v-col cols="12" md="3" lg="3" sm="3">
        <v-card color="teal darken-1" dark elevation="4">
          <v-card-title class="text-center"> Pasien Hari Ini </v-card-title>
          <v-card-text>
            <p class="text-h4">
              <span class="mdi mdi-account-supervisor"></span>
              {{ totalPasienHarini }} Pasien
            </p>
          </v-card-text>
        </v-card>
        <v-card color="orange darken-1" dark elevation="4" class="mt-1">
          <v-card-title class="text-center" elevation="4">
            Pasien Besok Hari
          </v-card-title>
          <v-card-text>
            <p class="text-h4">
              <span class="mdi mdi-account-supervisor"></span>
              {{ totalPasienBesokHari }} Pasien
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" lg="4" sm="4">
        <v-card elevation="4">
          <v-card-title>Antrian Pasien Pertanggal</v-card-title>
          <v-card-text>
            <v-text-field
              type="date"
              @change="totalAntrianPerDokter(), getTotalJadwalOperasi()"
              v-model="tglHari"
              outlined
              small
            ></v-text-field>
            <ul class="list-group list-group-flush">
              <li
                class="list-group-item list-group-item-action flex-column align-items-start"
                v-for="(antrianItem, indexAntrian) of listDokter"
                :key="indexAntrian"
              >
                <!-- <p>{{ antrianItem.profile }}</p>
                <small>3 days ago</small>

                <span class="badge badge-danger"
                  >{{ antrianItem.totalPasien }} Pasien</span
                > -->
                <div class="d-flex w-100 justify-content-between">
                  <p class="mb-1">
                    <b color="blue">{{ antrianItem.profile }}</b>
                  </p>
                  <span class="text-danger"
                    ><b>{{ antrianItem.totalPasien }} Pasien</b></span
                  >
                </div>
                <p class="mb-1">
                  {{ antrianItem.unit_name }}
                </p>
                <!-- <small>{{antrianItem.sub_unit_name}}</small> -->
              </li>
            </ul>
          </v-card-text>
        </v-card>
        <v-card v-if="isDokter" class="mt-1">
          <v-card-title>Tarik Data Jasa Dokter</v-card-title>
          <v-card-text
            v-if="
              tgllahir2 === tgllahir &&
              tmptlahir2.toLowerCase() === tmptlahir.toLowerCase()
            "
          >
            <v-text-field
              min="2024-03"
              type="month"
              v-model="bulanjasa"
            ></v-text-field>
            <ul class="list-group list-group-flush">
              <div>
                <v-btn class="btn btn-block" dark @click="getJasa('slipgaji')"
                  >Slip Gaji</v-btn
                >
                <v-btn
                  class="btn btn-block"
                  dark
                  color="success"
                  @click="getJasa('1/1')"
                  >RJ BPJS</v-btn
                >
                <v-btn
                  class="btn btn-block"
                  dark
                  color="info"
                  @click="getJasa('2/1')"
                  >RJ NAKER</v-btn
                >
                <v-btn
                  class="btn btn-block"
                  dark
                  color="primary"
                  @click="getJasa('3/1')"
                  >RJ UMUM</v-btn
                >
                <v-btn
                  class="btn btn-block"
                  dark
                  color="red"
                  @click="getJasa('4/1')"
                  >RJ COVID</v-btn
                >
                <v-btn
                  class="btn btn-block"
                  dark
                  color="success"
                  @click="getJasa('1/2')"
                  >RI BPJS</v-btn
                >
                <v-btn
                  class="btn btn-block"
                  dark
                  color="info"
                  @click="getJasa('2/2')"
                  >RI NAKER</v-btn
                >
                <v-btn
                  class="btn btn-block"
                  dark
                  color="primary"
                  @click="getJasa('3/2')"
                  >RI UMUM</v-btn
                >
                <v-btn
                  class="btn btn-block"
                  dark
                  color="red"
                  @click="getJasa('4/2')"
                  >RI COVID</v-btn
                >
                <v-btn
                  class="btn btn-block"
                  dark
                  color="blue"
                  @click="getJasa('3/3')"
                  >KLINIK</v-btn
                >
              </div>
            </ul>
          </v-card-text>
          <v-card-text v-else>
            <v-text-field
              label="Masukkan Tanggal Lahir Anda"
              type="date"
              v-model="tgllahir2"
            ></v-text-field>
            <v-text-field
              label="Masukkan Tempat Lahir Anda"
              type="text"
              v-model="tmptlahir2"
            ></v-text-field>
          </v-card-text>
        </v-card>
        <v-card class="mt-1" v-if="totalJadwalDokter.length > 0">
          <v-card-title>Jadwal Operasi</v-card-title>
          <v-card-subtitle
            >Total Pasien {{ totalJadwalDokter.length }}</v-card-subtitle
          >
          <v-card-text>
            <v-text-field
              type="date"
              outlined
              @change="getTotalJadwalOperasi(), totalAntrianPerDokter()"
              v-model="tglHari"
            ></v-text-field>
            <v-text-field
              type="text"
              placeholder="Cari"
              @change="getTotalJadwalOperasi"
              v-model="searchNama"
            ></v-text-field>
            <nav aria-label="Page navigation example">
              <ul class="pagination">
                <li class="page-item">
                  <a class="page-link" @click="initPermintaanPreviosPage"
                    >Previous</a
                  >
                </li>
                <li class="page-item">
                  <a
                    class="page-link"
                    v-html="permintaan_pagination_container"
                  ></a>
                </li>
                <li class="page-item">
                  <a class="page-link" @click="initPermintaanNextPage">Next</a>
                </li>
              </ul>
            </nav>
            <div
              class="list-group"
              v-for="(operasiItem, indexOperasi) of totalJadwalDokter"
              :key="indexOperasi"
            >
              <div
                class="list-group-item list-group-item-action flex-column align-items-start"
              >
                <div class="d-flex justify-content-between">
                  <p class="mb-1 text-primary">
                    {{ operasiItem.patient_name }} ({{ operasiItem.sex_name }})
                  </p>
                  <small>{{ operasiItem.tanggaloperasi }}</small>
                </div>
                <p class="mb-1">
                  Umur <b>{{ operasiItem.umur }}</b> Dengan Diagnosa
                  <b
                    ><i>{{ operasiItem.diagnosa }} , </i></b
                  >
                  Tindakan
                  <b
                    ><i>{{ operasiItem.jenistindakan }}</i></b
                  >
                  <br />
                  Keterangan : {{ operasiItem.keterangan }}
                </p>
                <small
                  ><b>{{ operasiItem.namapoli }}</b>
                  {{ operasiItem.dpjp }}</small
                >
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="5" lg="5" sm="5">
        <v-card elevation="4">
          <v-card-title class="text-center">Lembar Konstulasi</v-card-title>
          <v-card-text>
            <input
              type="text"
              v-model="searchKonsultasi"
              @blur="getDataListKonsultasi()"
              class="form-control form-control-lg mb-2"
              placeholder="Cari Nama Pasien"
              name=""
              id=""
            />
            <nav
              aria-label="Page navigation example"
              class="justify-content-center"
            >
              <ul class="pagination">
                <li class="page-item">
                  <a
                    class="page-link"
                    @click="initPermintaanPreviosPageKonsultasi"
                    >Sebelumnya</a
                  >
                </li>
                <li class="page-item">
                  <a
                    class="page-link"
                    v-html="permintaan_pagination_containerKonsultasi"
                  ></a>
                </li>
                <li class="page-item">
                  <a class="page-link" @click="initPermintaanNextPageKonsultasi"
                    >Selanjutnya</a
                  >
                </li>
              </ul>
            </nav>
            <div
              class="list-group"
              v-for="(itemList, i) of listKonsultasi"
              :key="i"
            >
              <!-- {{itemList}} -->
              <div
                class="list-group-item list-group-item-action flex-column align-items-start mb-2"
              >
                <h6>Nama Pasien : {{ itemList.patient_name }}</h6>

                <div class="d-flex w-100 justify-content-between">
                  <h6 class="mb-0">
                    <v-icon left medium>mdi-doctor</v-icon
                    >{{
                      itemList.namaDokter.text ? itemList.namaDokter.text : ""
                    }}
                    &nbsp;
                  </h6>
                </div>
                <small
                  >Tanggal Dikonsultasikan :
                  <b
                    ><u>{{ itemList.tanggal }}</u></b
                  ></small
                >
                <p>
                  Konsultasi Yang Diminta :
                  <b
                    ><u>{{ itemList.konsultasiYangDiminta }}</u></b
                  >
                </p>
                <v-divider></v-divider>

                <p class="mb-2">
                  <span class="red--text"
                    ><v-icon left small>mdi-file</v-icon> Diagnosis</span
                  >
                  : {{ itemList.diagnosis }}<br />
                  <span class="red--text"
                    ><v-icon left small>mdi-history</v-icon> Riwayat Klinik
                    Singkat</span
                  >
                  : {{ itemList.riwayat }}
                </p>
                <v-divider />

                <div class="mt-1">
                  <v-textarea
                    outlined
                    v-model="itemList.jawaban"
                    @blur="simpanjawaban(itemList)"
                    :disabled="cekKonsul(itemList)"
                    label="Jawaban Konsultasi"
                  ></v-textarea>

                  <v-row centered>
                    <v-col cols="12" lg="6" md="6" sm="6">
                      <v-btn
                        color="pink darken-1"
                        class="btn-block"
                        @click="showdiaglogusg(itemList)"
                        dark
                        >HASIL USG</v-btn
                      >
                    </v-col>
                    <v-col cols="12" lg="6" md="6" sm="6">
                      <v-btn
                        color="teal darken-1"
                        medium
                        class="btn-block"
                        @click="showDialogPenunjang(itemList)"
                        dark
                        >Hasil Penunjang</v-btn
                      >
                    </v-col>
                    <v-col cols="12" lg="6" md="6" sm="6">
                      <v-btn
                        color="cyan lighten-1"
                        @click="cetaknya(itemList, '')"
                        class="btn-block"
                        dark
                        medium
                        >Lembar Konsultasi</v-btn
                      >
                    </v-col>
                    <v-col
                      v-if="
                        pegawai.is_dokter &&
                        pegawai?.sub_unit_id_dokter != itemList.namaDokter.id
                      "
                      cols="12"
                      lg="6"
                      md="6"
                      sm="6"
                    >
                      <v-btn
                        @click="kirimkedokter(itemList)"
                        color="green darken-1"
                        dark
                        medium
                        class="btn-block"
                        >Kirim Ke Dokter</v-btn
                      >
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-dialog v-model="dialogUSG" max-width="780">
        <v-card>
          <v-card-title class="headline"> Form USG </v-card-title>
          <v-card-text class="mt-3">
            <h4>
              <b>Nama Pasien : </b>{{ dataPatientLembarKonsul.patient_name }}
            </h4>
            <h4><b>No. RM : </b>{{ dataPatientLembarKonsul.no_rm }}</h4>

            <textarea
              placeholder="Hasil Bacaan"
              v-model="content"
              class="form-control"
              rows="10"
            ></textarea>
            <v-divider class="mt-3 mb-6" />
            <h3><b>Hasil Bacaaan</b></h3>
            <v-divider class="mt-3 mb-6" />
            <div class="mt-2 m-1" v-html="rawHtml"></div>
            <v-btn color="green" @click="cetakprint" class="mt-2" dark
              >Cetak Hasil USG</v-btn
            >
          </v-card-text>
          <v-card-actions>
            <v-spacer />

            <v-btn color="primary darken-2" @click="simpanhasilusg" text>
              Simpan USG</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogpenunjang" max-width="480">
        <v-card>
          <v-card-title class="headline">Penunjang </v-card-title>

          <v-card-text>
            <p>
              <b>Nama Pasien : </b>{{ dataPatientLembarKonsul.patient_name }}
              <br />
              <b>No.Rm : </b>{{ dataPatientLembarKonsul.no_rm }}
            </p>
            <ListLaborHome
              :registration_id="reg_id"
              :no_rm="no_rm"
              ref="listlabor"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
      </div>
    </div>

  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
// eslint-disable-next-line no-unused-vars
import MobileDetect from 'mobile-detect'
import ListLaborHome from '../components/ListLaborHome.vue'

import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg,
  baseUrlEmr
} from '../plugins/supports'
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ListLaborHome
  },
  data () {
    return {
      totalPasienHarini: 0,
      totalPasienBesokHari: 0,
      pegawai: '',
      employee_id: '',
      subUnitId: '',
      tab: 0,
      reg_id: '',
      searchNama: '',
      dataPatientLembarKonsul: '',
      dialogUSG: false,
      no_rm: '',
      content: '',
      dialogpenunjang: false,
      permintaans_page: 1,
      permintaans_per_page: 9,
      permintaan_pagination_container: '0-0 of 0',
      permintaans_total: '',
      type: 'month',
      tglHari: '',
      tglHariOperasi: '',
      types: ['month', 'week', 'day', '4day'],
      mode: 'stack',
      modes: ['stack', 'column'],
      listDokter: [],
      totalJadwalDokter: [],

      bulanjasa: '',
      showmessg: '',
      isDokter: 0,
      tgllahir: '',
      rawHtml: '',
      tmptlahir: '',
      isMobile: false,
      tgllahir2: '',
      tmptlahir2: '',

      // LEMBAR KONSULTASI
      listKonsultasi: [],
      searchKonsultasi: '',
      permintaans_pageKonsultasi: 1,
      permintaans_per_pageKonsultasi: 9,
      permintaan_pagination_containerKonsultasi: '0-0 of 0',
      permintaans_totalKonsultasi: '',
      dialogActionNBtnLKirimUsg: () => {
        this.dialogUSG = false
      },
      dialogDisableNBtnKirimUSG: false
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  created () {
    this.totalPasien()
    if (localStorage.getItem('user')) {
      setTimeout(() => {
        this.pegawai = JSON.parse(localStorage.getItem('user'))
        this.employee_id = this.pegawai.employee_id
        this.subUnitId = this.pegawai.employee_id
        this.isDokter = this.pegawai.is_dokter
          ? parseInt(this.pegawai.is_dokter)
          : 0
        this.tgllahir = this.pegawai.birth_date
        this.tmptlahir = this.pegawai.place_of_birth
        this.totalPasien(this.employee_id)
        this.totalAntrianPerDokter()
        this.getTotalJadwalOperasi()
        this.getDataListKonsultasi()
        this.cekDevice()
      }, 1000)
    }
  },
  methods: {
    showDialogPenunjang (RESULTS) {
      setTimeout(() => {
        console.log(RESULTS)
        this.dialogpenunjang = true
        this.dataPatientLembarKonsul = RESULTS
        this.reg_id = RESULTS.registration_id.toString()
        this.no_rm = RESULTS.no_rm.toString()
      }, 100)

      setTimeout(() => {
        this.$refs.listlabor.getHistoryLabor()
      }, 100)
    },
    showdiaglogusg (RESULTS) {
      this.dialogUSG = true
      this.dataPatientLembarKonsul = RESULTS
      setTimeout(() => {
        this.getHasilUsg()
      }, 100)
    },
    isTabletOrMobile () {
      const userAgent = navigator.userAgent.toLowerCase()
      const isTablet = /(ipad|tablet|(android(?!.*mobi)))/i.test(userAgent)
      const isMobile =
        /android.*(mobi|phone)|iphone|ipod|blackberry|iemobile|opera mini/i.test(
          userAgent
        )
      return { isTablet, isMobile }
    },
    isMobiles () {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    },
    cetaknya (item, view) {
      // console.log(item)
      window
        .open(
          baseUrlEmr +
            `laporan-medis/cetak-lembar-konsultasi?folio_id=${item.folio_id}&id_lembar=${item.id_lembar_konsultasi}`,
          '_blank'
        )
        .focus()
    },
    cekDevice () {
      // console.log(navigator.userAgent)

      // this.isMobile =
      if (this.isMobiles() && window.innerWidth <= 768) {
        this.showmessg = 'MOBILE DEVICE' + ' ' + window.innerWidth
      } else {
        this.showmessg = 'DEKSTOP DEVICE' + ' ' + window.innerWidth
      }
      // console.log(!!md.mobile())
    },
    getJasa (key) {
      var hmm = this
      if (this.bulanjasa === '') {
        return alert('silahkan pilih bulan terlebih dahulu')
      }
      var data
      if (key === 'slipgaji') {
        data = {
          bulan: this.bulanjasa,
          id: this.employee_id
        }
        var enc = btoa(JSON.stringify(data))
        window.open(
          'https://his.rs-syafira.com/api/keuangan/printjasa/api3?enc=' + enc,
          'MyWindow',
          'width=800,height=1000'
        )
      } else {
        data = {
          bulan: this.bulanjasa,
          id: this.employee_id,
          tipebayar: key.split('/')[0],
          jenis: key.split('/')[1]
        }
        var enc2 = btoa(JSON.stringify(data))
        window.open(
          'https://his.rs-syafira.com/api/keuangan/printjasa/api2?enc=' + enc2,
          'MyWindow',
          'width=1500,height=1000'
        )
      }
      setTimeout(function () {
        hmm.tgllahir2 = ''
        hmm.tmptlahir2 = ''
        hmm.bulanjasa = ''
      }, 60000)
    },
    totalAntrianPerDokter () {
      // eslint-disable-next-line no-undef
      $.post(
        baseUrl +
          'jadwal-kontrol/jumlah-pasien-per-dokter?employee_id=' +
          this.employee_id +
          '&tgl=' +
          this.tglHari,
        (response) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = response
          if (con) {
            this.listDokter = results
          } else {
            errorMsg(msg)
          }
        },
        'JSON'
      )
    },
    totalPasien (v) {
      apiKedua
        .get('jadwal-kontrol/jumlah-pasien?employee_id=' + v)
        .then((r) => {
          this.totalPasienHarini = r.data.results[0]
          this.totalPasienBesokHari = r.data.results[1]
        })
    },
    getTotalJadwalOperasi () {
      // eslint-disable-next-line no-undef
      $.post(
        baseUrl + 'jadwal-kontrol/jadwal-operasi',
        {
          employee_id: this.employee_id,
          tgl: this.tglHari,
          pg: this.permintaans_page,
          ppg: this.permintaans_per_page,
          pls: this.searchNama
        },
        (response) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = response
          if (con) {
            const pagination = results.pagination
            this.permintaan_pagination_container = pagination
            this.permintaans_total = results.total_pages
            this.totalJadwalDokter = results.list
          } else {
            errorMsg(msg)
          }
        },
        'JSON'
      )
    },

    showKontrol ({ nativeEvent, event }) {
      console.log(event)
      console.log(nativeEvent)
    },
    getEventColor (event) {
      return event.color
    },
    rnd (a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
    initPermintaanPreviosPage () {
      var currentPage = parseInt(this.permintaans_page)
      if (currentPage > 1) {
        currentPage = currentPage - 1
      } else {
        currentPage = 1
      }
      this.permintaans_page = currentPage
      this.getTotalJadwalOperasi()
    },
    initPermintaanNextPage () {
      let no = 1
      var currentPage = parseInt(this.permintaans_page)
      var totalPages = parseInt(this.permintaans_total)

      // console.log(currentPage)
      // console.log(totalPages)
      no = currentPage + 1

      if (currentPage === totalPages) {
        no = currentPage
      }
      // $('#permintaans_page').val(no)
      this.permintaans_page = no
      this.getTotalJadwalOperasi()
    },
    cekKonsul (RESULTS) {
      if (this.pegawai.is_dokter === 1) {
        return true
      } else {
        return false
      }
    },
    cekId (RESULTS) {
      const myArray = [
        '1523',
        '492',
        '888',
        '507',
        '1685',
        '75',
        '155',
        '1104'
      ]
      return myArray.some((item) => item === RESULTS)
    },
    getDataListKonsultasi () {
      // eslint-disable-next-line no-undef
      $.post(
        baseUrlV2 + 'pasien/list-konsultasi-dokter',
        {
          subunitid: this.subUnitId,
          pg: this.permintaans_pageKonsultasi,
          ppg: this.permintaans_per_pageKonsultasi,
          pls: this.searchKonsultasi
        },
        (response) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = response
          const pagination = results.pagination
          this.permintaan_pagination_containerKonsultasi = pagination
          this.permintaans_totalKonsultasi = results.total_pages
          this.listKonsultasi = results.list
          // console.log(response)
        },
        'JSON'
      )
    },
    initPermintaanPreviosPageKonsultasi () {
      var currentPage = parseInt(this.permintaans_page)
      if (currentPage > 1) {
        currentPage = currentPage - 1
      } else {
        currentPage = 1
      }
      this.permintaans_pageKonsultasi = currentPage
      this.getDataListKonsultasi()
    },
    initPermintaanNextPageKonsultasi () {
      let no = 1
      var currentPage = parseInt(this.permintaans_pageKonsultasi)
      var totalPages = parseInt(this.permintaans_totalKonsultasi)

      // console.log(currentPage)
      // console.log(totalPages)
      no = currentPage + 1

      if (currentPage === totalPages) {
        no = currentPage
      }
      // $('#permintaans_page').val(no)
      this.permintaans_pageKonsultasi = no
      this.getDataListKonsultasi()
    },
    simpanjawaban (RESULTS) {
      // console.log(RESULTS)

      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'igd/simpan-jawaban-konsultasi',
        {
          id_lembar_konsultasi: RESULTS.id_lembar_konsultasi,
          jawaban: RESULTS.jawaban
        },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            successMsg(msg)
          } else {
            errorMsg(msg)
          }
        },
        'json'
      )
    },
    simpanhasilusg () {
      // console.log(this.dataPatientLembarKonsul)
      const RESULTS = this.dataPatientLembarKonsul
      const KONSUL = this.dataPatientLembarKonsul.namaDokter
      // console.log(KONSUL.id)
      // eslint-disable-next-line no-undef
      const demografi = LZString.decompressFromBase64(RESULTS.demografi)
      // console.log()
      const demografiConvert = JSON.parse(demografi)

      // console.log(demografiConvert)

      // eslint-disable-next-line no-unused-vars
      var data = {
        noRm: demografiConvert.demografi.no_rekam_medis,
        pegawai: this.pegawai.employee_id,
        folio_id: demografiConvert.demografi.folio_id,
        registration_id: demografiConvert.demografi.reg_id,
        sub_unit_id: KONSUL.id,
        namaPegawai: this.pegawai.employee_name,
        hasilUsg: this.content
      }
      console.log(data)

      if (!this.content) {
        errorMsg('Hasil USG Tidak Boleh Kosong')
        return true
      }

      // eslint-disable-next-line no-undef
      $.post(
        baseUrl + 'emr/simpan-hasil-usg',
        data,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            successMsg(msg)
            this.getHasilUsg()
          } else {
            errorMsg(msg)
          }
        },
        'JSON'
      )
    },
    getHasilUsg () {
      const RESULTS = this.dataPatientLembarKonsul
      // eslint-disable-next-line no-undef
      const demografi = LZString.decompressFromBase64(RESULTS.demografi)
      const demografiConvert = JSON.parse(demografi)
      console.log(demografiConvert)

      // eslint-disable-next-line camelcase, no-unused-vars
      const registration_id = demografiConvert.demografi.reg_id
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'emr/get-hasil-usg',
        { reg_id: registration_id },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            const regex = /(<([^>]+)>)/gi

            this.content = results.pemeriksaan.replace(regex, '')
            this.rawHtml = results.pemeriksaan

            successMsg(msg)
          } else {
            errorMsg(msg)
          }
        },
        'JSON'
      )
    },
    cetakprint () {
      const RESULTS = this.dataPatientLembarKonsul
      // eslint-disable-next-line no-undef
      const demografi = LZString.decompressFromBase64(RESULTS.demografi)
      const demografiConvert = JSON.parse(demografi)
      console.log(demografiConvert)

      // eslint-disable-next-line camelcase, no-unused-vars
      const registration_id = demografiConvert.demografi.reg_id
      const folioid = demografiConvert.demografi.folio_id
      window
        .open(
          baseUrlEmr +
            'laporan-medis/cetak-hasil-usg-medis?folio_id=' +
            folioid,
          '_blank'
        )
        .focus()
    }
  }
}
</script>
<style scoped>
.v-application ul,
.v-application ol {
  padding-left: 0px !important;
}
v-card__subtitle,
.v-card__text {
  font-size: 0.85rem !important;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: 0.0071428571em;
}

.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  width: 100%;
  padding: 5px !important;
}
</style>
